<template>
  <div>
    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_name">{{ $t('categories.name') }}</label>
              <input v-model="filters.name" type="text" id="f_name" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">
                {{ $t('status') }}
              </label>
              <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{ $t('search') }}</b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="actions" slot-scope="props">
          <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
            <router-link v-if="$can('laundry_services.create')" class="dropdown-item" :to="`/settings/laundry-services-prices/${props.row.id}`">
              {{ $t('MENU.laundry_services') }}
            </router-link>
          </b-dropdown>
          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-categories-list",
  components: {},
  data() {
    return {
      mainRoute: 'settings/categories_list',

      showAdvancedSearch: false,
      filters: {
        name: null,
        is_active: null,
      },
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      columns: ['name', 'parent_name', 'actions'],

      idEditing: null,
      isEditing: false,


      disabledButton: false,
      validation: [],
      dataList: [],
    }
  },
  computed: {

    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('categories.name'),
          parent_name: that.$t('categories.parent_category'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.categories_list")}]);
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.is_active = null;

      this.$refs.table.refresh();
    },




  },
};
</script>
